import 'reflect-metadata'
import {BenefitWithUsage} from './Benefit'
import {ExcessWithUsage} from './Excess'
import {Life} from './Life'
import {DomainMappings} from '../mappings/DomainMappings'
import {Type} from 'class-transformer'
import {format, isWithinInterval} from 'date-fns'
import {BenefitType, PlanYearId} from './types'
import {isEmpty} from 'lodash-es'
import {maxValidDate} from '@peachy/utility-kit-pure'

export class Plan {

    @Type(() => Life)
    readonly life: Life
    @Type(() => Date)
    readonly startDate: Date
    @Type(() => PlanYear)
    readonly planYears: PlanYear[]

    constructor(
        readonly id: string,
        life: Life,
        startDate: Date,
        planYears: PlanYear[]
    ) {
        this.life = life
        this.startDate = startDate
        this.planYears = planYears
    }

    get referenceNumber() {
        return DomainMappings.peachifyUuid(this.id)
    }

    get lastAcceptableClaimSubmissionDateOnAnyBenefit() {
        const dates = this.currentPlanYear?.benefits?.map(it => it.lastAcceptableClaimSubmissionDate) ?? []
        return maxValidDate(...dates)
    }

    /**
     * @returns the next refresh date if the customer hasn't cancelled, undefined otherwise
     */
    get nextRefreshDate() {
        return !this.life.isCancelled ? this.currentPlanYear?.end : undefined
    }

    get currentPlanYear() {
        const planYear = this.planYears.find(it => it.isCurrent)
        return new CurrentPlanYear(planYear)
    }

    get planYearsLatestFirst() {
        return [...this.planYears].reverse()
    }

    get firstPlanYear() {
        return this.planYears[0]
    }

    getPlanYearAtDateOf(givenDate: Date) {
        return this.planYearsLatestFirst.find(it => isWithinInterval(givenDate, it))
    }

    getBenefitInPlanYear(planYearId: string, benefitType: BenefitType) {
        return this.getPlanYearById(planYearId)?.getBenefitByType(benefitType)
    }

    getBenefitInCurrentPlanYear(benefitType: BenefitType) {
        return this.currentPlanYear?.getBenefitByType(benefitType)
    }

    private getPlanYearById(planYearId: string) {
        return this.planYears.find(it => it.id === planYearId)
    }
}

export class PlanYear implements Interval {

    @Type(() => Date)
    start: Date

    @Type(() => Date)
    end: Date

    @Type(() => BenefitWithUsage)
    benefits: BenefitWithUsage[] = []

    @Type(() => ExcessWithUsage)
    excess: ExcessWithUsage

    isCurrent: boolean

    constructor(props: Pick<PlanYear, 'start' | 'end' | 'benefits' | 'excess' | 'isCurrent'>) {
        this.start = props.start
        this.end = props.end
        this.benefits = props.benefits
        this.excess = props.excess
        this.isCurrent = props.isCurrent
    }

    static idOf(interval: Interval): PlanYearId {
        // if you change this implementation you will have to migrate everywhere we persist plan year ids to match
        return `${format(interval.start, 'yyyy')}_${format(interval.end, 'yyyy')}`
    }

    get id() {
        return PlanYear.idOf(this)
    }

    getBenefitByType(benefitType: BenefitType) {
        return this.benefits.find(it => it.type === benefitType)
    }

    getExcessApplicableTo(givenBenefitType: BenefitType) {
        return this.excess?.appliesTo(givenBenefitType) ? this.excess : undefined
    }

    get cashLimitedBenefits() {
        return this.benefits.filter(it => it.isCashLimited())
    }

}


// it only makes sense to query about "claimablility", "covercheckability", and "active" on the current plan year so all those helper methods only live here to better express intent
export class CurrentPlanYear extends PlanYear {

    get claimableBenefits() {
        const rightNow = new Date()
        return this.getClaimableBenefitsAtDate(rightNow)
    }

    get coverCheckableBenefits() {
        const rightNow = new Date()
        return this.getCoverCheckableBenefitsAtDate(rightNow)
    }
    
    get activeBenefits() {
        const rightNow = new Date()
        return this.getActiveBenefitsAtDate(rightNow)
    }

    hasAnyClaimableBenefits() {
        return !isEmpty(this.claimableBenefits)
    }

    hasAnyCoverCheckableBenefits() {
        return !isEmpty(this.coverCheckableBenefits)
    }

    hasActiveBenefit(benefitType: BenefitType) {
        return !isEmpty(this.activeBenefits.find(it => it.isType(benefitType)))
    }

    getClaimableBenefitsAtDate(givenDate: Date) {
        return this.benefits.filter(it => it.wasAcceptingClaimSubmissionsOn(givenDate))
    }

    getCoverCheckableBenefitsAtDate(givenDate: Date) {
        return this.benefits.filter(it => it.wasAcceptingCoverCheckSubmissionsOn(givenDate))
    }

    getActiveBenefitsAtDate(givenDate: Date) {
        return this.benefits.filter(it => it.wasActiveOn(givenDate))
    }
}
