import { getMimeTypeBasePart, getMimeType, MIME_JPEG, MimeType, PropertiesOnly } from '@peachy/utility-kit-pure'
import { QuestionAnswer} from './types'

type OptionalMediaProps = {
    thumbnailUri?: string
    description?: string
}
export class Media {

    readonly extension: string
    readonly filename: string
    readonly thumbnailUri: string
    readonly description: string

    static fromJson(json: PropertiesOnly<Omit<Media, 'shortType'>>) {
        if (json) {
            const {uri, type, ...optionalProps} = json
            return new Media(uri, type, optionalProps)
        }
    }

    // should tidy this really naive implementation
    static fromBestGuess(uri: string, optionalProps?: OptionalMediaProps) {
        // workout mime type and default to image if we don't know. ¯\_(ツ)_/¯ I told you it was naive!
        const mimeType = getMimeType(uri) ?? MIME_JPEG
        return new Media(uri, mimeType, optionalProps)
    }

    private constructor(readonly uri: string, readonly type: MimeType, optionalProps: OptionalMediaProps) {
        this.extension = uri.slice(uri.lastIndexOf('.'))
        this.filename = uri.slice((uri.lastIndexOf('/') + 1))
        this.thumbnailUri = optionalProps?.thumbnailUri
        this.description = optionalProps?.description
    }
    
    isApplication() {
        return this.shortType === 'application'
    }

    isVideo() {
        return this.shortType === 'video'
    }

    isImage() {
        return this.shortType === 'image'
    }

    get shortType() {
        return getMimeTypeBasePart(this.type)
    }

    toString() {
        return this.uri
    }

    getAnyThumbnailableUri() {
        return this.thumbnailUri ?? (this.isImage() ? this.uri : undefined)
    }

    isThumbnailable() {
        return !!this.getAnyThumbnailableUri()
    }

    get thumbnailFilename() {
        return this.thumbnailUri.slice((this.thumbnailUri.lastIndexOf('/') + 1))
    }

    move(moveFunc: (source: string, destination: string) => Promise<void>, destination: string): Promise<Media> {
        const props = {
            uri: this.uri,
            thumbnailUri: this.thumbnailUri,
            type: this.type,
            description: this.description
        }
        const promises = [
            moveFunc(this.uri, `${destination}/${this.filename}`)
                .then(() => props.uri = `${destination}/${this.filename}`)
        ]
        if (this.thumbnailUri) {
            promises.push(
                moveFunc(this.thumbnailUri, `${destination}/${this.thumbnailFilename}`)
                    .then(() => props.thumbnailUri = `${destination}/${this.thumbnailFilename}`))
        }
        return Promise.all(promises)
            .then(() => new Media(props.uri, props.type, props))
    }

}

export const answerIsMedia = (answer: QuestionAnswer): answer is Media => !!(answer as Media)?.uri