
export * from './src/socket-kit'
export * from './src/call-kit'

export * from './src/Switchboard'
export * from './src/Call'
export * from './src/CallCenter'
export * from './src/Duplex'

export function ofType<T>(x: T): T {
    return x
}

        import * as me from '@peachy/sockets-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/juliancook/git/peachy/peachy-mono-repo/sockets/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/sockets-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    