import {ClaimActivity, prettyPrintBenefit, prettyPrintClaimStage, prettyPrintDecision} from '@peachy/repo-domain'
import {currencyFromPence, ukStyleDate} from '@peachy/utility-kit-pure'
import {
    ColumnDef,
    createColumnHelper,
    createSolidTable,
    getCoreRowModel,
    getSortedRowModel,
    SortingState
} from '@tanstack/solid-table'
import {createSignal} from 'solid-js'
import {Table} from '../../../components/Table/Table'
import {isEmpty} from 'lodash-es'

type ClaimActivityTableProps = {
    claims: ClaimActivity[]
    customerAwsSub: string
}
export function ClaimActivityTable(props: ClaimActivityTableProps) {

    const [data, setData] = createSignal(props.claims)
    const [sorting, setSorting] = createSignal<SortingState>([])

    const ch = createColumnHelper<ClaimActivity>()

    const columns: ColumnDef<ClaimActivity>[] = [
        ch.accessor(claim => claim.referenceNumber, {
            header: 'Ref#',
            cell: info => <a target="_blank" class="refNum" href={`/customer/${props.customerAwsSub}/claim/${info.row.original.id}`}>{info.getValue()}</a>
        }),
        ch.accessor(claim => prettyPrintClaimStage(claim), {
            header: 'Stage',
            cell: info => <span class="stage">{info.getValue()}</span>
        }),
        ch.accessor(claim => ukStyleDate(claim.dateSubmitted), {
            header: 'Submitted',
            cell: info => <span class="date-submitted">{info.getValue()}</span>
        }),
        ch.accessor(claim => ukStyleDate(claim.assessment?.referralDate), {
            header: 'Referred',
            cell: info => <span class="date-referred">{info.getValue()}</span>
        }),
        ch.accessor(claim => getBenefitsFrom(claim), {
            header: 'Benefit(s)',
            cell: info => <span class="benefits">{info.getValue()}</span>
        }),
        ch.accessor(claim => {
            const overallTotalApprovedInPence = claim.decision?.approvedCosts?.overallTotalApprovedInPence
            const decision = !claim.decision ? 'Pending' : prettyPrintDecision(claim.decision)
            return decision + (overallTotalApprovedInPence ? ` (${currencyFromPence(overallTotalApprovedInPence)})` : '')
        }, {
            header: 'Decision',
            cell: info => <span class="decision">{info.getValue()}</span>
        }),
        ch.accessor(claim => claim.assessedPlanYearIds.join(', '), {
            header: 'Plan Year(s)',
            cell: info => <span class="plan-years">{info.getValue()}</span>
        })
    ]

    const table = createSolidTable({
        get data() {
            return data()
        },
        columns,
        state: {
            get sorting() {
                return sorting()
            },
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel()
    })

    return <Table table={table}/>
}

function getBenefitsFrom(claim: ClaimActivity) {
    const benefits = claim.assessedBenefits.map(prettyPrintBenefit)
    if (isEmpty(benefits)) {
        benefits.push(prettyPrintBenefit(claim.customerDeclaredBenefitType))
    }
    return benefits.join(', ')
}
