import 'reflect-metadata'
import {addWeeks, areIntervalsOverlapping, isBefore, isEqual, max} from 'date-fns'
import {DateInterval, Optional, PropertiesOnly} from '@peachy/utility-kit-pure'
import {assign, values} from 'lodash-es'
import {BenefitLimit, BenefitUtilisationPolicy, Obligation, CashLimited, Obligations, Limited } from '../config/ProductConfigService'
import {BenefitType, ClaimStages} from './types'

type BenefitConstructorProps = PropertiesOnly<
    Omit<Benefit, 
        'limit' | 
        'limitValue' | 
        'hasBeenCancelled' | 
        'lastAcceptableClaimSubmissionDate'
    >
>

export class Benefit {
    
    readonly id: string
    readonly startDate: Date
    readonly endDate: Date
    readonly type: BenefitType
    readonly parentType?: BenefitType
    readonly utilisationPolicy?: BenefitUtilisationPolicy

    constructor(props: BenefitConstructorProps) {
        assign(this, props)
    }

    startedOnOrBefore(givenDate: Date) {
        return isBefore(this.startDate, givenDate) || isEqual(this.startDate, givenDate)
    }

    wasCancelledBefore(dateTime: Date) {
        return this.endDate && isBefore(this.endDate, dateTime)
    }

    get lastAcceptableClaimSubmissionDate() {
        const lodgementWindowInWeeks = this.claimUtilisationPolicy()?.lodgementWindowInWeeks
        return this.endDate && lodgementWindowInWeeks ? addWeeks(this.endDate, lodgementWindowInWeeks) : undefined
    }

    /**
     * @param proposedSubmissionDate the date on which to check if CLAIM submissions would have been accepted for on the benefit
     * @returns true if the benefit is configured with any obligation to claim AND it had started AND was not cancelled $this.utilisationPolicy?.claim?.lodgementWindowInWeeks or more prior to the given date, false otherwise.
     * Further explanation: You have a this.utilisationPolicy?.claim?.lodgementWindowInWeeks week window after treatment/payment in which to submit a claim. It's possible that someone could have just had some treatment and then immediately cancel ther cover.  They still have some weeks to submit that claim
     * Additionally some benefits are never configured to be claimable for example Virtual GP
     */
    wasAcceptingClaimSubmissionsOn(proposedSubmissionDate: Date) {
        return this.wasAcceptingClaimSubmissionsAtAnytimeDuring({start: proposedSubmissionDate, end: proposedSubmissionDate})
    }

    /**
     * @param proposedSubmissionDate the date on which to check if COVER CHECK submissions would have been accepted for on the benefit
     * @returns true if the benefit is configured with any obligation to cover check AND it had started AND was not cancelled prior to the given date, false otherwise. 
     * Further explanation: There is no point in checking cover for a benefit that you have cancelled, any treatment you have would inheriently be after the cancellation date so would definitely be declined
     * Additionally some benefits are never configured to be cover checkable for example Virtual GP
     */
    wasAcceptingCoverCheckSubmissionsOn(proposedSubmissionDate: Date) { 
        return this.wasAcceptingCoverCheckSubmissionsAtAnytimeDuring({start: proposedSubmissionDate, end: proposedSubmissionDate})
    }
    
    /**
     * @param proposedSubmissionDate the date on which to check if the benefit was active on
     * @returns true if the benefit is had started AND was not cancelled prior to the given date, false otherwise.
    */
    wasActiveOn(proposedSubmissionDate: Date) { 
        return this.wasActiveAnytimeDuring({start: proposedSubmissionDate, end: proposedSubmissionDate})
    }

    wasAcceptingClaimSubmissionsAtAnytimeDuring(proposedInterval: DateInterval) {
        const itsEverPossible = this.anyObligation(this.claimUtilisationPolicy())
        const claimableInterval = {start: this.startDate, end: this.lastAcceptableClaimSubmissionDate ?? max([proposedInterval.end, this.startDate])}
        return itsEverPossible && areIntervalsOverlapping(proposedInterval, claimableInterval, {inclusive: true})
    }

    wasAcceptingCoverCheckSubmissionsAtAnytimeDuring(proposedInterval: DateInterval) {
        const itsEverPossible = this.anyObligation(this.coverCheckUtilisationPolicy())
        return itsEverPossible && this.wasActiveAnytimeDuring(proposedInterval)
    }

    wasActiveAnytimeDuring(proposedInterval: DateInterval) {
        const activeInterval = {start: this.startDate, end: this.endDate ?? max([proposedInterval.end, this.startDate])}
        return areIntervalsOverlapping(proposedInterval, activeInterval, {inclusive: true})
    }

    private anyObligation(obligated?: {obligation?: Obligation}) {
        const anyObligation: Obligation[] = values(Obligations)
        return anyObligation.includes(obligated?.obligation)
    }
 
    get hasBeenCancelled() {
        return this.wasCancelledBefore(new Date())
    }

    isType(givenType: string) {
        return this.type === givenType
    }

    withUsage(planYearId: string, coverUsedInPence: number) {
        return new BenefitWithUsage(this, planYearId, coverUsedInPence)
    }

    get limit() {
        return this.utilisationPolicy?.limit
    }

    get limitValue() {
        return this?.limit?.value
    }

    protected ifLimited<ReturnType>(doThis: (limit: BenefitLimit) => Optional<ReturnType> ) {
        if (this.isLimited()) {
            return doThis(this.limit)
        }
    }

    isLimited(): this is Limited<typeof this> {
        return !!this.limit
    }
    
    isCashLimited(): this is CashLimited<typeof this> {
        return this.limit?.unit === 'PENCE'
    }

    private claimUtilisationPolicy() {
        return this.utilisationPolicy?.[ClaimStages.CLAIM]
    }

    private coverCheckUtilisationPolicy() {
        return this.utilisationPolicy?.[ClaimStages.COVER_CHECK]
    }

}

export class BenefitWithUsage extends Benefit {
    
    constructor(benefit: Benefit, readonly planYearId: string, readonly limitUsed: number) {
        super (benefit)
    }

    get percentageLimitUsed() {
        return super.ifLimited(limit => 
            Math.min(this.limitUsed / limit.value * 100, 100)
        )
    }

    get percentageLimitRemaining() {
        return super.ifLimited(() => 
            100 - this.percentageLimitUsed
        )
    }

    get limitRemaining() {
        return this.ifLimited(limit => 
            Math.max(limit.value - this.limitUsed, 0)
        )
    }

    limitRemainingIsGreaterThanOrEqualTo(value: number) {
        return this.limitRemaining >= value
    }

}