import {
    RepoAddress,
    RepoAppointment,
    RepoApprovedClaimCost,
    RepoBankDetails,
    RepoBenefit,
    RepoClaimActivity,
    RepoClaimActivitySubmissionReason,
    RepoClaimAssessment,
    RepoClaimInvoiceLineItem,
    RepoCoverCheckRequestedTreatment,
    RepoDecision,
    RepoEnquiry,
    RepoExcess,
    RepoExessUsage,
    RepoGp,
    RepoHospitalAdmission,
    RepoInProgressAppointmentBooking,
    RepoInProgressClaimActivity,
    RepoLife,
    RepoPlan,
    RepoTerminologyItem
} from '../repo/types'
import {Life} from '../domain/Life'
import {Address} from '../domain/Address'
import {formatISO, parseISO} from 'date-fns'
import * as datesTz from 'date-fns-tz'
import {Plan, PlanYear} from '../domain/Plan'
import _ from 'lodash-es'
import {Benefit} from '../domain/Benefit'
import {instanceToPlain, plainToInstance} from 'class-transformer'
import {Decision} from '../domain/Decision'
import {ClaimActivity, InProgressClaimActivity} from '../domain/ClaimActivity'
import {Appointment, InProgressAppointmentBooking} from '../domain/Appointment'
import {RegisteredGp} from '../domain/RegisteredGp'
import {Enquiry} from '../domain/enquiry/Enquiry'
import {Address as HealthHeroAddress} from '@peachy/health-hero-client'
import {Address as LookupAddress} from '@peachy/core-domain-pure'
import {GeoLocation, GetCareFilters} from '../domain/types'
import {NhsOrganisation} from '../domain/NhsOrganisation'
import {IsoDatePartOnly, Optional, PropertiesOnly, UK_TIMEZONE} from '@peachy/utility-kit-pure'
import {ClaimAssessment} from '../domain/assessment/ClaimAssessment'
import {ClaimActivitySubmissionReason} from '../domain/assessment/ClaimActivitySubmissionReason'
import {HospitalAdmission} from '../domain/assessment/HospitalAdmission'
import {ClaimInvoiceLineItem} from '../domain/assessment/ClaimInvoiceLineItem'
import {ApprovedClaimCosts, PlanYearBenefitAmount, PlanYearExcessAmount} from '../domain/assessment/ApprovedClaimCosts'
import {CoverCheckRequestedTreatment} from '../domain/assessment/CoverCheckRequestedTreatment'
import {TerminologyItem} from '@peachy/nhs-pure'
import {Excess} from '../domain/Excess'
import {BankDetails} from '../domain/BankDetails'

export class DomainMappings {

    static readonly fromRepo = {
        toAddress(repoAddress?: RepoAddress): Optional<Address> {
            return repoAddress ? new Address(
                repoAddress.line1,
                repoAddress.line2,
                repoAddress.city,
                repoAddress.county,
                repoAddress.postcode,
                repoAddress.country,
                repoAddress.geolocation,
            ) : undefined
        },

        toLife(repoLife: RepoLife) {
            return repoLife ? new Life(
                repoLife.id,
                repoLife.awsSub,
                repoLife.firstName,
                repoLife.lastName,
                repoLife.email,
                repoLife.phoneNumber,
                this.toEuropeLondonIsoDatePartOnly(repoLife.dateOfBirth),
                repoLife.gender,
                repoLife.onboarded,
                repoLife.type,
                repoLife.isPolicyOwner,
                repoLife.isPrimary,
                this.toDate(repoLife.dateCancelled),
                this.toAddress(repoLife.address),
                this.toRegisteredGp(repoLife.registeredGp),
                repoLife.vgpUserId,
                this.toBankDetails(repoLife.bankAccount)
            ) : undefined
        },

        toLives(repoLife: RepoLife[]): Life[] {
            return repoLife.map(it => this.toLife(it))
        },

        toInProgressClaimActivity(repoInProgressClaimActivity: RepoInProgressClaimActivity, enquiry: Enquiry) {
            return repoInProgressClaimActivity ? new InProgressClaimActivity(
                repoInProgressClaimActivity.id,
                repoInProgressClaimActivity.stage,
                this.toDate(repoInProgressClaimActivity.dateCreated),
                enquiry,
            ) : undefined
        },

        toClaimActivity(repoClaimActivity: RepoClaimActivity, life: Life) {
            const claim = repoClaimActivity ? new ClaimActivity(
                repoClaimActivity.id,
                repoClaimActivity.stage,
                this.toDate(repoClaimActivity.dateCreated),
                repoClaimActivity.enquiryId,
                repoClaimActivity.submissionId,
                this.toDate(repoClaimActivity.dateSubmitted),
                repoClaimActivity.customerDeclaredBenefitType,
                repoClaimActivity.treatment,
                life,
                repoClaimActivity.costInPence,
                this.toDate(repoClaimActivity.treatmentDate),
                this.toDecision(repoClaimActivity.decision),
                repoClaimActivity.amountReimbursedInPence,
                repoClaimActivity.media ?? {},
                this.toClaimAssessment(repoClaimActivity.assessment)
            ) : undefined
            return claim
        },

        toInProgressAppointmentBooking(repoInProgressAppointmentBooking: RepoInProgressAppointmentBooking, enquiry: Enquiry) {
            return repoInProgressAppointmentBooking ? new InProgressAppointmentBooking(
                repoInProgressAppointmentBooking.id,
                repoInProgressAppointmentBooking.type,
                this.toDate(repoInProgressAppointmentBooking.dateCreated),
                enquiry,
            ) : undefined
        },

        toAppointment(repoAppointment: RepoAppointment, whoFor: Life) {
            return repoAppointment ? new Appointment(
                repoAppointment.id,
                repoAppointment.type,
                this.toDate(repoAppointment.dateCreated),
                repoAppointment.enquiryId,
                this.toDate(repoAppointment.date),
                whoFor,
                repoAppointment.whoWith,
                this.toAddress(repoAppointment.location),
                repoAppointment.takesPlaceInApp,
                repoAppointment.attended,
                this.toDate(repoAppointment.dateCancelled),
                repoAppointment.serviceProviderMetadata,
            ) : undefined
        },

        toDecision(repoDecision?: RepoDecision) {
            return repoDecision ? new Decision(
                this.toDate(repoDecision.date),
                repoDecision.type,
                repoDecision.notes,
                this.toApprovedCosts(repoDecision.approvedCosts, repoDecision.excessUsage)
            ) : undefined
        },

        toApprovedCosts(repoApprovedCosts?: RepoApprovedClaimCost[], repoExcessUsage?: RepoExessUsage[]) {
            return new ApprovedClaimCosts({
                planYearExcessUsage: (repoExcessUsage ?? []).map(it => new PlanYearExcessAmount({
                    excessId: it.excessId,
                    planYearId: it.planYearId,
                    amountInPence: it.amountInPence
                })),
                planYearBenefitApprovals: (repoApprovedCosts ?? []).map(it => new PlanYearBenefitAmount({
                    planYearId: it.planYearId,
                    benefitType: it.benefitType,
                    amountInPence: it.amountInPence
                }))
            })
        },

        toClaimAssessment(repoClaimAssessment: RepoClaimAssessment) {
            return repoClaimAssessment ? new ClaimAssessment({
                submissionReasons: (repoClaimAssessment.submissionReasons ?? []).map(it => this.toClaimActivitySubmissionReason(it)),
                hospitalAdmissions: (repoClaimAssessment.hospitalAdmissions ?? []).map(it => this.toHospitalAdmission(it)),
                invoiceLineItems: (repoClaimAssessment.invoiceLineItems ?? []).map(it => this.toClaimInvoiceLineItem(it)),
                referralDate: this.toDate(repoClaimAssessment.referralDate),
                linkedClaimActivityIds: repoClaimAssessment.linkedClaimActivityIds ?? [],
                requestedTreatments: (repoClaimAssessment.requestedTreatments ?? []).map(it => this.toRequestedTreatment(it))
            }) : undefined
        },

        toClaimActivitySubmissionReason(repoSubmissionReason?: RepoClaimActivitySubmissionReason) {
            return repoSubmissionReason ? new ClaimActivitySubmissionReason({
                id: repoSubmissionReason.id,
                onsetDate: this.toDate(repoSubmissionReason.onsetDate),
                disorder: this.toTerminologyItem(repoSubmissionReason.disorder),
                symptoms: repoSubmissionReason.symptoms?.map(it => this.toTerminologyItem(it)) ?? []
            }) : undefined
        },

        toHospitalAdmission(repoHospitalAdmission?: RepoHospitalAdmission) {
            return repoHospitalAdmission ? new HospitalAdmission({
                id: repoHospitalAdmission.id,
                admissionDate: this.toDate(repoHospitalAdmission.admissionDate),
                dischargeDate: this.toDate(repoHospitalAdmission.dischargeDate)
            }) : undefined
        },

        toClaimInvoiceLineItem(repoInvoiceLineItem?: RepoClaimInvoiceLineItem) {
            return repoInvoiceLineItem ? new ClaimInvoiceLineItem({
                id: repoInvoiceLineItem.id,
                procedure: this.toTerminologyItem(repoInvoiceLineItem.procedure),
                treatmentAddress: repoInvoiceLineItem.treatmentAddress,
                treatmentDate: this.toDate(repoInvoiceLineItem.treatmentDate),
                treatmentPaymentDate: this.toDate(repoInvoiceLineItem.treatmentPaymentDate),
                planYearId: repoInvoiceLineItem.planYearId,
                benefitType: repoInvoiceLineItem.benefitType,
                hospitalAdmissionId: repoInvoiceLineItem.hospitalAdmissionId,
                invoiceAmountInPence: repoInvoiceLineItem.invoiceAmountInPence,
                eligibleAmountInPence: repoInvoiceLineItem.eligibleAmountInPence,
                reasonId: repoInvoiceLineItem.reasonId,
                notes: repoInvoiceLineItem.notes
            }) : undefined
        },

        toRequestedTreatment(repoRequestedTreatment?: RepoCoverCheckRequestedTreatment) {
            return repoRequestedTreatment ? new CoverCheckRequestedTreatment({
                id: repoRequestedTreatment.id,
                procedure: this.toTerminologyItem(repoRequestedTreatment.procedure),
                reasonId: repoRequestedTreatment.reasonId,
                benefitType: repoRequestedTreatment.benefitType,
                approved: repoRequestedTreatment.approved,
                notes: repoRequestedTreatment.notes
            }) : undefined
        },


        toTerminologyItem(repoTerm: RepoTerminologyItem): TerminologyItem {
            return repoTerm
        },

        toPlan(repoPlan: RepoPlan, repoLife: RepoLife, planYears: PlanYear[]): Optional<Plan> {
            return repoPlan ? new Plan(
                repoPlan.id,
                this.toLife(repoLife),
                this.toDate(repoPlan.startDate),
                planYears
            ) : undefined
        },
        
        toBenefit(repoBenefit?: RepoBenefit) {
            const {startDate, endDate, ...rest} = repoBenefit
            return repoBenefit ? new Benefit ({
                startDate: this.toDate(startDate),
                endDate: this.toDate(endDate),
                ...rest
            }) : undefined
        },

        toExcess(repoExcess?: RepoExcess) {
            return repoExcess ? new Excess({
                id: repoExcess.id,
                amountInPence: repoExcess.amountInPence,
                benefitTypes: repoExcess.benefitTypes
            }) : undefined
        },

        toEnquiry(repoEnquiry: RepoEnquiry): Enquiry {
            // @ts-ignore
            return repoEnquiry ? plainToInstance(Enquiry, repoEnquiry as object) : undefined
        },

        toRegisteredGp(repoGp?: RepoGp) {
            return repoGp ? new RegisteredGp(
                repoGp.id,
                repoGp.practiceName,
                this.toAddress(repoGp.address),
                repoGp.nhsOrganisationId
            ) : undefined
        },

        toBankDetails(repoBankDetails: RepoBankDetails) {
            return repoBankDetails ? new BankDetails(repoBankDetails) : undefined
        },

        toDate(str?: string | null) {
            return str ? parseISO(str) : undefined
        },

        // legacy lives could have a dateOfBirth with a timestamp, eg: 1985-08-11T00:00:00.000Z.  this fixes that...
        // NB: even after repo migration we can't guarantee they will stay migrated until all customers are on app
        // version greater than 1.4 (where loads of dodgy dob manipulation was getting persisted)
        toEuropeLondonIsoDatePartOnly(potentiallyTimezonedIsoDate?: string | null) {
            const isNotTimezoned = potentiallyTimezonedIsoDate?.length === 10
            if (isNotTimezoned) {
                return potentiallyTimezonedIsoDate as IsoDatePartOnly
            } else {
                const asDate = this.toDate(potentiallyTimezonedIsoDate)
                return (asDate ? datesTz.formatInTimeZone(asDate, UK_TIMEZONE, 'yyyy-MM-dd') : undefined) as IsoDatePartOnly
            }
        }
    }

    static readonly fromDomain = {

        toRepoClaimActivity(claimActivity: ClaimActivity): RepoClaimActivity {
            return {
                id: claimActivity.id,
                stage: claimActivity.stage,
                dateCreated: this.toRepoDate(claimActivity.dateCreated),
                enquiryId: claimActivity.enquiryId,
                customerDeclaredBenefitType: claimActivity.customerDeclaredBenefitType,
                submissionId: claimActivity.submissionId,
                dateSubmitted: this.toRepoDate(claimActivity.dateSubmitted),
                decision: this.toRepoDecision(claimActivity.decision),
                treatment: claimActivity.treatment,
                treatmentReceiverId: claimActivity.treatmentReceiver.id,
                costInPence: claimActivity.costInPence,
                treatmentDate: this.toRepoDate(claimActivity.treatmentDate),
                assessment: this.toRepoClaimAssessment(claimActivity.assessment)
            }
        },

        toRepoClaimAssessment(claimAssessment?: ClaimAssessment | PropertiesOnly<ClaimAssessment>): RepoClaimAssessment {
            return claimAssessment ? {
                referralDate: this.toRepoDate(claimAssessment.referralDate),
                hospitalAdmissions: claimAssessment.hospitalAdmissions.map(it => this.toRepoHospitalAdmission(it)),
                submissionReasons: claimAssessment.submissionReasons.map(it => this.toRepoClaimActivitySubmissionReason(it)),
                invoiceLineItems: claimAssessment.invoiceLineItems.map(it => this.toRepoClaimInvoiceLineItem(it)),
                linkedClaimActivityIds: claimAssessment.linkedClaimActivityIds,
                requestedTreatments: claimAssessment.requestedTreatments.map(it => this.toRepoRequestedTreatment(it))
            } : undefined
        },

        toRepoClaimActivitySubmissionReason(submissionReason: ClaimActivitySubmissionReason): RepoClaimActivitySubmissionReason {
            return  {
                id: submissionReason.id,
                onsetDate: this.toRepoDate(submissionReason.onsetDate),
                symptoms: submissionReason.symptoms.map(it => this.toRepoTerminologyItem(it)),
                disorder: this.toRepoTerminologyItem(submissionReason.disorder)
            }
        },

        toRepoTerminologyItem(term: TerminologyItem): RepoTerminologyItem {
            return term
        },

        toRepoHospitalAdmission(hospitalAdmission: HospitalAdmission): RepoHospitalAdmission {
            return  {
                id: hospitalAdmission.id,
                admissionDate: this.toRepoDate(hospitalAdmission.admissionDate),
                dischargeDate: this.toRepoDate(hospitalAdmission.dischargeDate)
            }
        },

        toRepoClaimInvoiceLineItem(invoiceLineItem: ClaimInvoiceLineItem): RepoClaimInvoiceLineItem {
            return {
                id: invoiceLineItem.id,
                procedure: this.toRepoTerminologyItem(invoiceLineItem.procedure),
                treatmentAddress: invoiceLineItem.treatmentAddress,
                treatmentDate: this.toRepoDate(invoiceLineItem.treatmentDate),
                treatmentPaymentDate: this.toRepoDate(invoiceLineItem.treatmentPaymentDate),
                planYearId: invoiceLineItem.planYearId,
                benefitType: invoiceLineItem.benefitType,
                hospitalAdmissionId: invoiceLineItem.hospitalAdmissionId,
                invoiceAmountInPence: invoiceLineItem.invoiceAmountInPence,
                eligibleAmountInPence: invoiceLineItem.eligibleAmountInPence,
                reasonId: invoiceLineItem.reasonId
            }
        },

        toRepoRequestedTreatment(requestedTreatment: CoverCheckRequestedTreatment): RepoCoverCheckRequestedTreatment {
            return {
                id: requestedTreatment.id,
                procedure: this.toRepoTerminologyItem(requestedTreatment.procedure),
                benefitType: requestedTreatment.benefitType,
                reasonId: requestedTreatment.reasonId,
                approved: requestedTreatment.approved
            }
        },

        toRepoInProgressClaimActivity(inProgressClaimActivity: InProgressClaimActivity): RepoInProgressClaimActivity {
            return {
                id: inProgressClaimActivity.id,
                stage: inProgressClaimActivity.stage,
                dateCreated: this.toRepoDate(inProgressClaimActivity.dateCreated),
                enquiryId: inProgressClaimActivity.enquiry.id
            }
        },

        toRepoAppointment(appointment: Appointment): RepoAppointment {
            return {
                id: appointment.id,
                type: appointment.type,
                dateCreated: this.toRepoDate(appointment.dateCreated),
                enquiryId: appointment.enquiryId,
                date: this.toRepoDate(appointment.date),
                whoForId: appointment.whoFor.id,
                whoWith: appointment.whoWith,
                location: this.toRepoAddress(appointment.location),
                takesPlaceInApp: appointment.takesPlaceInApp,
                attended: appointment.attended,
                dateCancelled: this.toRepoDate(appointment.dateCancelled),
                serviceProviderMetadata: instanceToPlain(appointment.serviceProviderMetadata)
            }
        },

        toRepoInProgressAppointmentBooking(inProgressAppointmentBooking: InProgressAppointmentBooking): RepoInProgressAppointmentBooking {
            return {
                id: inProgressAppointmentBooking.id,
                type: inProgressAppointmentBooking.type,
                dateCreated: this.toRepoDate(inProgressAppointmentBooking.dateCreated),
                enquiryId: inProgressAppointmentBooking.enquiry.id,
                serviceProviderMetadata: instanceToPlain(inProgressAppointmentBooking.serviceProviderMetadata)
            }
        },

        toRepoEnquiry(enquiry: Enquiry): RepoEnquiry {
            return instanceToPlain(enquiry)
        },

        toRepoAddress(address: Address): Optional<RepoAddress> {
            return address ? {
                line1: address.line1,
                line2: address.line2,
                city: address.city,
                county: address.county,
                postcode: address.postcode,
                country: address.country,
                geolocation: address.geolocation
            } : undefined
        },

        toRepoDecision(decision: Decision): Optional<RepoDecision> {
            return decision ? {
                date: this.toRepoDate(decision.date),
                type: decision.type,
                approvedCosts: this.toRepoApprovedCosts(decision.approvedCosts),
                excessUsage: this.toRepoExcessUsage(decision.approvedCosts),
                notes: decision.notes
            } : undefined
        },

        toRepoApprovedCosts(approvedCosts?: ApprovedClaimCosts): RepoApprovedClaimCost[] {
            return approvedCosts?.planYearBenefitApprovals?.map(it => ({
                    planYearId: it.planYearId,
                    benefitType: it.benefitType,
                    amountInPence: it.amountInPence
            })) ?? []
        },

        toRepoExcessUsage(approvedCosts?: ApprovedClaimCosts): RepoExessUsage[] {
            return approvedCosts?.planYearExcessUsage?.map(it => ({
                    planYearId: it.planYearId,
                    excessId: it.excessId,
                    amountInPence: it.amountInPence
            })) ?? []
        },

        toRepoDate(date: Date | undefined) {
            return date ? formatISO(date) : undefined
        }
    }

    static peachifyUuid(uuid: string, takeChars = 5) {
        //todo issue with uniqueness and maybe dodgy words also how this is generated for the policy doc plan / policy? They should match
        const uuidWithoutHyphens = _.replace(uuid, /-/g, '')
        return 'PCY '+ uuidWithoutHyphens?.substring(0, takeChars)?.toUpperCase()
    }

}

export function healthHeroAddressFromPeachyAddress(peachyAddress?: Address): HealthHeroAddress | undefined {
    return peachyAddress && buildHealthHeroAddress(
        peachyAddress.line1,
        peachyAddress.line2,
        peachyAddress.city,
        peachyAddress.postcode,
        'UK' //should capture this rather than assume
    )
}

export function peachyAddressFromNhsOrganisation(nhsOrg?: NhsOrganisation): Address | undefined {
    return nhsOrg && buildPeachyAddress(
        nhsOrg.Address1,
        nhsOrg.Address2,
        nhsOrg.City,
        nhsOrg.County,
        nhsOrg.Postcode
    )
}

export function peachyAddressFromLookupAddress(lookupAddress: LookupAddress): Address | undefined {
    return lookupAddress && buildPeachyAddress(
        lookupAddress.building?.join(', ') ?? lookupAddress.display,
        lookupAddress.settlement?.length > 1 ? lookupAddress.settlement[0] : undefined,
        lookupAddress.settlement?.length > 1 ? lookupAddress.settlement[1] : lookupAddress.settlement[0],
        lookupAddress.county,
        lookupAddress.postcode,
        lookupAddress.country,
        lookupAddress.location ? {
            latitude: lookupAddress.location.lat,
            longitude: lookupAddress.location.lng,
        } : undefined
    )
}

export function peachyGpFromNhsOrganisation(nhsOrg?: NhsOrganisation): RegisteredGp | undefined {
    return nhsOrg && new RegisteredGp(
        undefined,
        nhsOrg.OrganisationName,
        peachyAddressFromNhsOrganisation(nhsOrg)!,
        nhsOrg.OrganisationID
    )
}

export function healthHeroSafePhoneNumber(phoneNumber: string) {
    return phoneNumber?.replace(/[^\d]/g, '')
}

export function getCareFiltersToMarketplaceQuery(filters: GetCareFilters) {
    return {
        locus: {
            lat: filters.location?.latitude,
            lng: filters.location?.longitude
        },
        rangeInMeters: filters.distance,
        // appointmentTypes: [],
        genderPreferences: filters?.gender ? [filters?.gender] : [],
        // costRanges: [],
        paediatric: filters.paediatric,
        searchTerms: filters.query ? [filters.query] : []
    }
}

function buildPeachyAddress(addLine1: string, addLine2: string | undefined, city: string, county: string, postcode: string, country?: string, location?: GeoLocation): Address {
    const {line1, line2} = moveLineTwoToLineOneIfLineOneEmpty(addLine1, addLine2)
    return new Address(line1!, line2, city, county, postcode, country, location)
}

//should force line2 to line1 on lambda before returning results
function buildHealthHeroAddress(addLine1: string, addLine2: string | undefined, city: string, postCode: string, countryCode: string): HealthHeroAddress {
    const {line1, line2} = moveLineTwoToLineOneIfLineOneEmpty(addLine1, addLine2)
    return {
        addressLine1: line1!,
        addressLine2: line2,
        city,
        postCode,
        countryCode
    }
}

function moveLineTwoToLineOneIfLineOneEmpty(addLine1: string|undefined, addLine2: string|undefined) {
    const line1 = addLine1 || addLine2
    const line2 = addLine1 && addLine2 ? addLine2 : undefined
    return {line1, line2}
}
