import {
    classList,
    currencyFromPence,
    Optional,
    penceToPounds,
    poundsToPence,
    ukStyleDate
} from '@peachy/utility-kit-pure'
import {
    CellContext,
    ColumnDef,
    createColumnHelper,
    createSolidTable,
    getCoreRowModel,
    getGroupedRowModel
} from '@tanstack/solid-table'
import {
    Accessor,
    createContext,
    createEffect,
    createSignal,
    For,
    Match,
    on,
    ParentProps,
    Show,
    Switch,
    useContext
} from 'solid-js'
import {Table} from '../../../components/Table/Table'
import {format, isValid} from 'date-fns'
import {isEmpty, sumBy} from 'lodash-es'
import s from './ProcessClaimActivity.module.css'

import {
    Autocomplete as AutocompleteRaw,
    AutocompleteMultiple as AutocompleteMultipleRaw,
    createListStore,
    ErrorWrapped,
    Html5DatePicker as Html5DatePickerRaw,
    Select as SelectRaw,
    SelectMultiple as SelectMultipleRaw,
    TextBox as TextBoxRaw,
    Toggle
} from '@peachy/client-kit'
import {
    ApprovedClaimCosts,
    ClaimActivity,
    ClaimActivitySubmissionReason,
    Enquiry,
    Plan,
    PlanYear,
    PlanYearBenefitAmount,
    PlanYearExcessAmount,
    prettyPrintBenefit,
    prettyPrintClaimStage,
    prettyPrintDecision,
    readablePlanYearShort
} from '@peachy/repo-domain'
import {InputCurrency as InputCurrencyRaw} from './deprecated/InputCurrency'
import {Flex} from '../../../components/deprecated/Flex/Flex'
import {Card} from '../../../components/Card/Card'
import {ModalOpener, useModalControl} from '../../../components/ModalOpener/ModalOpener'
import {Spinner} from '../../../components/Spinner/Spinner'
import {
    asValidatable,
    ClaimAssessmentState,
    HasId,
    PlanYearBenefitSettlement,
    PlanYearSettlementExcessGroup,
    Validatable,
    ValidatableClaimActivitySubmissionReason,
    ValidatableClaimInvoiceLineItem,
    ValidatableCoverCheckRequestedTreatment,
    ValidatableHospitalAdmission,
    WritableClaimInvoiceLineItem
} from './ClaimAssessmentState'
import {TerminologyItem} from '@peachy/nhs-pure'
import {createStore} from 'solid-js/store'

const Autocomplete = ErrorWrapped(AutocompleteRaw)
const AutocompleteMultiple = ErrorWrapped(AutocompleteMultipleRaw)
const Html5DatePicker = ErrorWrapped(Html5DatePickerRaw)
const Select = ErrorWrapped(SelectRaw)
const SelectMultiple = ErrorWrapped(SelectMultipleRaw)
const InputCurrency = ErrorWrapped(InputCurrencyRaw)
const TextBox = ErrorWrapped(TextBoxRaw)

const THIN = {'font-weight': 300}
const ThinTextSpan = (props: ParentProps<any>) => <span style={THIN}>{props.children}</span>

const ClaimAssessmentContext = createContext<ClaimAssessmentState>()

type ProcessClaimActivityProps = {
    claimActivityId: string
    enquiry: Enquiry
    plan: Plan
    otherClaimActivities: ClaimActivity[]
    readOnlyMode: boolean
    forceEditMode: boolean
    onSaveOrApplyDecision?: () => void
}

function useClaimProcessingApi() {
    return useContext(ClaimAssessmentContext)
}

const approveStyle = {"background-color": 'var(--peachy-green)'}
const referStyle = {"background-color": 'var(--warning)'}

export function ProcessClaimActivity(props: ProcessClaimActivityProps) {

    const api = new ClaimAssessmentState(
        props.claimActivityId,
        props.enquiry,
        props.plan,
        props.otherClaimActivities,
        props.readOnlyMode,
        props.forceEditMode,
        props.onSaveOrApplyDecision
    )

    return (
        <Show when={!!api.claim()}>

            <ClaimAssessmentContext.Provider value={api}>
                <Spinner isOpen={api.showSpinner()}/>

                <IfClaim>
                    <Show when={!isEmpty(api.coverChecks())}>
                        <h3>Linked Cover Checks</h3>
                        <LinkCoverChecks/>
                        <br/>
                    </Show>
                </IfClaim>

                <h3 id="reasonCapture">{prettyPrintClaimStage(api.claim())} Reason <AddButton onClick={() => api.addDraftReasonForClaimActivity()} disabled={api.readOnly}/></h3>
                <ClaimActivityReasonCapture/>

                <IfClaim>
                    <Show when={!isEmpty(api.hospitalAdmissions)}>
                        <h3 id="admissionsCapture">Hospital Admissions <AddButton onClick={() => api.addDraftHospitalAdmission()} disabled={api.readOnly}/></h3>
                        <HospitalAdmissionsCapture/>
                    </Show>

                    <h3 id="invoiceCaptue">Invoice Items <AddButton onClick={() => api.addDraftInvoiceLineItem()} disabled={api.readOnly}/></h3>
                    <ClaimInvoiceCapture/>
                </IfClaim>

                <IfCoverCheck>
                    <h3 id="treatmentCapture">Requested Treatment <AddButton onClick={() => api.addDraftRequestedTreatments()} disabled={api.readOnly}/></h3>
                    <RequestedTreatmentCapture/>
                </IfCoverCheck>

                <Flex row justifyContentEnd>
                    <ErrorsAndWarnings warnings={() => api.miscWarnings()}/>
                </Flex>

                <Flex row justifyContentSpaceBetween>
                    {/* make sure that the only way to save changes after decision made is to apply the whole decison again (so full validation for the decision is run and we don't end up with bad data) */}
                    <Show when={api.claim().isPendingDecision()} fallback={<div/>}>
                        <button disabled={api.readOnly} onClick={() => api.saveAssessmentAndSync()}>Save changes</button>
                    </Show>

                    <span>
                        <ModalOpener trigger={<button style={referStyle} disabled={api.readOnly || api.isReferred()}>Refer{api.isReferred() ? `red ${ukStyleDate(api.claim().assessment.referralDate)}` : ''}</button>}>
                            <ReferModal/>
                        </ModalOpener>
                        &nbsp;

                        <Show when={api.claim().isClaim()}>
                            <ApplyClaimDecisionActions/>
                        </Show>

                        <Show when={api.claim().isCoverCheck()}>
                            <ApplyCoverCheckDecisionActions/>
                        </Show>

                    </span>
                </Flex>
            </ClaimAssessmentContext.Provider>
        </Show>
    )
}

function ApplyClaimDecisionActions() {

    const api = useClaimProcessingApi()

    return <>
        <ModalOpener trigger={<button disabled={api.readOnly}>Decline</button>} triggerEnabled={() => !api.getAndShowAnyFieldErrors()}>
            <DeclineModal/>
        </ModalOpener>
        &nbsp;
        <ModalOpener trigger={<button style={approveStyle} disabled={api.readOnly}>Approve</button>} triggerEnabled={() => !api.getAndShowAnyFieldErrors()}>
            <ApproveModal/>
        </ModalOpener>
    </>
}

function ApplyCoverCheckDecisionActions() {

    const api = useClaimProcessingApi()

    const distinctApprovals = () => [...new Set(api.requestedTreatments.map(it => it.approved))]

    const availableAction = () => {
        const approvals = distinctApprovals()
        return approvals.length > 1 ? {action: 'partial', formatted: 'Partially Approve', style: approveStyle} :
        approvals[0] === true ? {action: 'approve', formatted: 'Approve', style: approveStyle} :
        approvals[0] === false ? {action: 'decline', formatted: 'Decline', style: {}} : undefined
    }

    return (
        <Show when={availableAction()}>
            <ModalOpener trigger={<button style={availableAction().style} disabled={api.readOnly}>{availableAction().formatted}</button>} triggerEnabled={() => !api.getAndShowAnyFieldErrors()}>
                <Switch>
                    <Match when={['approve', 'partial'].includes(availableAction().action)}>
                        <ApproveModal partial={availableAction().action === 'partial'}/>
                    </Match>
                    <Match when={availableAction().action === 'decline'}>
                        <DeclineModal/>
                    </Match>
                </Switch>
            </ModalOpener>
        </Show>
    )

}

function ReferModal() {
    const api = useClaimProcessingApi()
    const modal = useModalControl()

    const [referralDate, setReferralDate] = createSignal(new Date())

    const errors = () => !isValid(referralDate()) ? 'required' : undefined

    const refer = async () => {
        if (!errors()) {
            await api.referAndSync(referralDate())
            modal.close()
        }
    }

    return <Card>
            <h3>Refer the decision</h3>
            <p>Date referred:</p>
            <Html5DatePicker
                value={referralDate()}
                onBlur={setReferralDate}
                error={errors()}
            />
            <Flex row justifyContentSpaceBetween>
                <button onClick={modal.close}>Cancel</button>
                <button disabled={!!errors()}
                    onClick={refer}
                    style={{"background-color": 'var(--peachy-green)'}}>Ok</button>
            </Flex>
        </Card>

}

function DeclineModal() {
    const modal = useModalControl()
    const api = useClaimProcessingApi()
    const errors = () => [...api.cannotDeclineReasons(), error()].filter(it => !!it)
    const warnings = () => api.miscWarnings()

    const [reason, setReason] = createSignal('')
    const [error, setError] = createSignal(undefined)

    const submitDecline = async () => {
        const success = await api.declineAndSync(reason())
        setError(!success ? 'Sorry, something went wrong. Please try again' : undefined)
        success && modal.close()
    }

    return <Card>
            <h3>Decline</h3>
            <Show when={isEmpty(errors())} fallback={<ErrorsAndWarnings errors={errors}/>}>
                <TextBox value={reason()} onChange={setReason} placeholder={'Reason for decline'}/>
                <p style={{'font-size': 'small', color: 'var(--warning)'}}>This is shown to the customer!</p>
                <ErrorsAndWarnings warnings={warnings}/>
                <Flex marginTop justifyContentSpaceBetween>
                    <button onClick={modal.close}>Cancel</button>
                    <button disabled={isEmpty(reason())} onClick={submitDecline}>Decline</button>
                </Flex>
            </Show>
        </Card>
}

type SettlementApprovals = {
    planYearBenefitApprovalsInPence: PlanYearBenefitAmount[]
    planYearApplicableExcessInPence: PlanYearExcessAmount[]
    errors: string[]
}
function ApproveModal(props: {partial?: boolean}) {

    const modal = useModalControl()
    const api = useClaimProcessingApi()
    const errors = () => [...api.cannotApproveReasons(), submitError()].filter(it => !!it)
    const warnings = () => api.miscWarnings()

    const captureSettlementApprovals = () => api.claim().isClaim()

    const [settlementApprovals, setSettlementApprovals] = createStore<SettlementApprovals>({} as SettlementApprovals)
    const anySettlementErrors = () => captureSettlementApprovals() && !isEmpty(settlementApprovals.errors)

    const [submitError, setSubmitError] = createSignal(undefined)

    const submitApprove = async () => {
        let approvedCosts: Optional<ApprovedClaimCosts>
        if (captureSettlementApprovals()) {
            approvedCosts = new ApprovedClaimCosts({
                planYearBenefitApprovals: settlementApprovals.planYearBenefitApprovalsInPence,
                planYearExcessUsage: settlementApprovals.planYearApplicableExcessInPence
            })
        }

        const success = await api.approveAndSync(approvedCosts)
        setSubmitError(!success ? 'Sorry, something went wrong. Please try again' : undefined)
        success && modal.close()
    }

    return <Card>
            <div style={{'overflow-y': 'scroll', 'max-height': '90vh'}}>
                <h3>Approve</h3>
                <Show when={isEmpty(errors())} fallback={<ErrorsAndWarnings errors={errors}/>}>
                    <Show when={captureSettlementApprovals()}>
                        <CaptureSettlementApprovals onChange={setSettlementApprovals}/>
                    </Show>
                    <ErrorsAndWarnings warnings={warnings}/>
                    <Flex marginTop justifyContentSpaceBetween>
                        <button onClick={modal.close}>Cancel</button>
                        <button disabled={anySettlementErrors()} onClick={submitApprove}>{props.partial ? 'Partially ' : ''}Approve</button>
                    </Flex>
                </Show>
            </div>
        </Card>
}

function ErrorsAndWarnings(props: {errors?: Accessor<string[]>, warnings?: Accessor<string[]>}) {
    const errors = () => props.errors?.() ?? []
    const warnings = () => props.warnings?.() ?? []

    return <Show when={!isEmpty([...errors(), ...warnings()])}>
        <ul style={{'list-style-type': 'disc', 'padding-left': '1em'}}>
            <For each={errors()}>{ error =>
                <li style={{color: 'var(--error)'}}>{error}</li>
            }</For>

            <For each={warnings()}>{ warning =>
                <li style={{color: 'var(--warning)'}}>{warning}</li>
            }</For>
        </ul>
    </Show>
}

function AddButton(props: {onClick: () => void, disabled?: boolean}) {
    return <i onClick={() => !props.disabled && props.onClick()} class='fa-solid fa-square-plus fa-button'/>
}

function IfClaim(props: ParentProps) {
    return <Show when={useClaimProcessingApi().claim().isClaim()}>
        {props.children}
    </Show>
}

function IfCoverCheck(props: ParentProps) {
    return <Show when={useClaimProcessingApi().claim().isCoverCheck()}>
        {props.children}
    </Show>
}

export function ClaimInvoiceCapture() {

    const api = useClaimProcessingApi()
    const [items, itemsApi] = [api.invoiceLineItems, api.invoiceLineItemsApi]

    const ch = createColumnHelper<ValidatableClaimInvoiceLineItem>()

    const onAdmissionsChangeUnlinkTheStaleOnes = () => {
        const admissionIds = api.hospitalAdmissions.map(it => it.id)
        items.forEach(it => {
            if (!admissionIds.includes(it.hospitalAdmissionId)) {
                itemsApi.patchById(it.id, {hospitalAdmissionId: undefined})
            }
        })
    }
    createEffect(onAdmissionsChangeUnlinkTheStaleOnes)

    const columns: ColumnDef<ValidatableClaimInvoiceLineItem>[] = [
        ch.accessor(item => item.procedure?.display, {
            id: 'Description',
            header: props => <>
                                <div>{props.column.id}</div>
                                <Show when={api.customerDeclaredTreatment}>
                                    <ThinTextSpan>{api.customerDeclaredTreatment} declared</ThinTextSpan>
                                </Show>
                            </>,
            cell: props => <Autocomplete <TerminologyItem>
                onChange={procedure => itemsApi.patchById(itemId(props), {procedure})}
                initialSearchTerm={props.getValue()}
                onBlur={() => item(props).visit('procedure')}
                error={item(props).getErrorIfVisitedFor('procedure')}
                disabled={api.readOnly}
                optionTextFn={it => it?.display ?? ''}
                optionComparisonValueFn={it => it?.code}
                lookupClient={{
                    lookup: async searchTerm => ({results: await api.searchProceduresTerminology(searchTerm), searchTerm})
                }}/>
        }),
        ch.accessor(item => item.reasonId, {
            header: 'Treating Reason',
            cell: props => <Flex row alignItemsCenter>
                            <span style={{'margin-right': '1ch'}}>
                                <Select
                                    pleaseChoose="Please choose"
                                    options={api.allPossibleReasonsForSubmission().filter(it => it.isValid())}
                                    onBlur={() => item(props).visit('reasonId')}
                                    error={item(props).getErrorIfVisitedFor('reasonId')}
                                    warning={item(props).getWarningFor('reasonId')}
                                    selected={props.getValue()}
                                    optionValueFn={it => it.id}
                                    optionTextFn={prettyPrintReason}
                                    onChange={reason => itemsApi.patchById(itemId(props), {reasonId: reason?.id})}
                                    disabled={api.readOnly}/>
                                </span>
                                <AddButton onClick={() => {
                                        const addedReason = api.addDraftReasonForClaimActivity({highlight: true})
                                        itemsApi.patchById(item(props).id, {reasonId: addedReason.id})
                                    }} disabled={api.readOnly}/>
                            </Flex>
        }),
        ch.accessor(item => item.treatmentAddress?.postcode, {
            header: 'Treatment Location',
            cell: props => <TextBox
                        placeholder="postcode only"
                        value={props.getValue() ?? ''}
                        realOnChange={postcode => itemsApi.patchById(itemId(props), {treatmentAddress: {postcode}})}
                        onBlur={() => item(props).visit('treatmentAddress')}
                        error={item(props).getErrorIfVisitedFor('treatmentAddress')}
                        disabled={api.readOnly}
                        />
        }),
        ch.accessor(item => item.treatmentDate, {
            id: 'Treatment Date',
            header: props => <>
                                <div>{props.column.id}</div>
                                <Show when={api.customerDeclaredTreatmentDate}>
                                    <ThinTextSpan>{ukStyleDate(api.customerDeclaredTreatmentDate)} declared</ThinTextSpan>
                                </Show>
                            </>,
            cell: props => <Html5DatePicker
                        value={props.getValue()}
                        onBlur={treatmentDate => {
                            item(props).visit('treatmentDate')
                            itemsApi.patchById(itemId(props), {treatmentDate})
                        }}
                        error={item(props).getErrorIfVisitedFor('treatmentDate')}
                        warning={item(props).getWarningFor('treatmentDate')}
                        disabled={api.readOnly}
                        />
        }),
        ch.accessor(item => item.treatmentPaymentDate, {
            header: 'Payment Date',
            cell: props => <Html5DatePicker
                        value={props.getValue()}
                        onBlur={treatmentPaymentDate => {
                            item(props).visit('treatmentPaymentDate')
                            itemsApi.patchById(itemId(props), {treatmentPaymentDate})
                        }}
                        error={item(props).getErrorIfVisitedFor('treatmentPaymentDate')}
                        warning={item(props).getWarningFor('treatmentPaymentDate')}
                        disabled={api.readOnly}
                        />
        }),
        ch.accessor(item => item.planYearId, {
            id: 'Plan Year',
            header: props => <>
                                <div>{props.column.id}</div>
                                <ThinTextSpan>Anniversary: {format(api.firstPlanYear.start, 'dd/MM')}</ThinTextSpan>
                            </>,
            cell: props => <Select
                            options={api.planYears}
                            onBlur={() => item(props).visit('planYearId')}
                            error={item(props).getErrorIfVisitedFor('planYearId')}
                            warning={item(props).getWarningFor('planYearId')}
                            selected={props.getValue()}
                            optionValueFn={it => it.id}
                            optionTextFn={it => readablePlanYearShort(it)}
                            onChange={planYear => itemsApi.patchById(itemId(props), {planYearId: planYear.id})}
                            disabled={api.readOnly}
                            />
        }),
        ch.accessor(item => item.benefitType, {
            id: 'Benefit',
            header: props => <>
                                <div>{props.column.id}</div>
                                <Show when={api.customerDeclaredBenefitText}>
                                    <ThinTextSpan>{api.customerDeclaredBenefitText} declared</ThinTextSpan>
                                </Show>
                            </>,
            cell: props => {
                        return <Select
                            pleaseChoose={!props.getValue() ? 'Please choose' : undefined}
                            options={api.getAllBenefitTypesThatCanEverBeClaimed()}
                            onBlur={() => item(props).visit('benefitType')}
                            error={item(props).getErrorIfVisitedFor('benefitType')}
                            warning={item(props).getWarningFor('benefitType')}
                            selected={props.getValue()}
                            optionTextFn={it => prettyPrintBenefit(it)}
                            onChange={benefitType => itemsApi.patchById(itemId(props), {benefitType})}
                            disabled={api.readOnly}
                        />
                    }
        }),
        ch.accessor(item => item.hospitalAdmissionId, {
            header: 'Hospital Admission',
            cell: props => <Flex row alignItemsCenter>
                                <span style={{'margin-right': '1ch'}}>
                                    <Select
                                        pleaseChoose="N/A"
                                        options={api.hospitalAdmissions}
                                        onBlur={() => item(props).visit('hospitalAdmissionId')}
                                        error={item(props).getErrorIfVisitedFor('hospitalAdmissionId')}
                                        warning={item(props).getWarningFor('hospitalAdmissionId')}
                                        selected={props.getValue()}
                                        optionValueFn={it => it.id}
                                        optionTextFn={it => `${ukStyleDate(it.admissionDate)} - ${ukStyleDate(it.dischargeDate)}`}
                                        onChange={selected => itemsApi.patchById(itemId(props), {hospitalAdmissionId: selected?.id})}
                                        disabled={api.readOnly}
                                        />
                                </span>
                                <AddButton onClick={() => {
                                        const addedAdmission = api.addDraftHospitalAdmission({highlight: true})
                                        itemsApi.patchById(item(props).id, {hospitalAdmissionId: addedAdmission.id})
                                    }} disabled={api.readOnly}/>
                            </Flex>
        }),
        ch.accessor(item => penceToPounds(item.invoiceAmountInPence), {
            header: 'Invoice Amount',
            cell: props => <Flex row alignItemsCenter>
                                <span style={{width: '15ch'}}>
                                    <InputCurrency
                                    value={props.getValue()}
                                    onBlur={() => item(props).visit('invoiceAmountInPence')}
                                    error={item(props).getErrorIfVisitedFor('invoiceAmountInPence')}
                                    onChange={value => {
                                        const patch: Partial<WritableClaimInvoiceLineItem> = {invoiceAmountInPence: poundsToPence(value ?? 0)}
                                        const prev = item(props)
                                        const wereNotPreviouslyDiverged = prev.eligibleAmountInPence === prev.invoiceAmountInPence
                                        if (wereNotPreviouslyDiverged) {
                                            patch.eligibleAmountInPence = patch.invoiceAmountInPence
                                        }
                                        itemsApi.patchById(itemId(props), patch)
                                    }}
                                    disabled={api.readOnly}
                                    />
                                </span>
                            </Flex>
        }),
        ch.accessor(item => penceToPounds(item.eligibleAmountInPence), {
            header: 'Eligible Amount',
            cell: props => <Flex row alignItemsCenter>
                            <span style={{width: '15ch'}}>
                                <InputCurrency
                                    value={props.getValue()}
                                    onBlur={() => item(props).visit('eligibleAmountInPence')}
                                    error={item(props).getErrorIfVisitedFor('eligibleAmountInPence')}
                                    onChange={value => {itemsApi.patchById(itemId(props), {eligibleAmountInPence: poundsToPence(value ?? 0)})}}
                                    disabled={api.readOnly}
                                    />
                                </span>
                            </Flex>
        }),
        ch.display({
            header: ' ',
            cell: props => <BinIcon hidden={api.readOnly || itemsApi.hasExactlyOneItem()} onClick={() => itemsApi.removeById(itemId(props))}/>
        }),
    ]

    const table = createSolidTable({
        get data() {
            return items.map(it => ({...it}))
        },
        columns,
        getCoreRowModel: getCoreRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
    })

    return <>
        <For each={items}>{it => <TargetedSuggestPlanYearHandler item={it}/>}</For>
        <Table table={table} rowClass={item => api.isHighlighted(item) ? s.newRowHighlight : undefined}/>
        <Flex row justifyContentEnd>
            <SettlementSummary/>
        </Flex>
    </>
}

function TargetedSuggestPlanYearHandler(props: {item: ValidatableClaimInvoiceLineItem}) {
    const api = useClaimProcessingApi()

    const noNeedToRunOnLoad = !!props.item.planYearId

    // specify exactly which props to track to stop effect being run on other hospital admission changes
    createEffect(on([() => props.item.treatmentDate, () => props.item.hospitalAdmissionId], () => {
        // don't muck about with plan year dates in read only or force edit mode
        if (!api.readOnly && !api.forceEditMode) {
            const suggestedPlayYear = api.suggestedPlanYearFor(props.item)
            if (suggestedPlayYear) {
                api.invoiceLineItemsApi.patchById(props.item.id, {planYearId: suggestedPlayYear.id})
            }
        }
    // defer: true to not run effect on creation - initial plan year value will be set by getDraftInvoiceLineItem or loaded from db.  we only want to run this on creation if there is no plan year selected yet (coz we know the UI will force it to be the first available year which then needs validation)

    }, {defer: noNeedToRunOnLoad}))
    return <></>
}

type Settleable = {
    id: string
    benefitSettlement: PlanYearBenefitSettlement
    amountToApproveInPence: number
}
type ValidatableSettleable = Validatable<Settleable>


type CaptureSettlementApprovalsProps = {
    onChange: (settlement: SettlementApprovals) => void
}
function CaptureSettlementApprovals(props: CaptureSettlementApprovalsProps) {
    console.log('capture approval amounts')
    const api = useClaimProcessingApi()

    const initSettleables = api.planYearBenefitSettlements().map(benefitSettlement => asValidatable({
        id: benefitSettlement.id,
        benefitSettlement,
        amountToApproveInPence: benefitSettlement.approvedAmountInPence ?? benefitSettlement.maxApprovable
    }))

    const [toSettle, toSettleApi] = createListStore<ValidatableSettleable>(initSettleables)

    const groupedSettleables = api.planYearExcessBenefitSettlements()

    const validate = (settleable: ValidatableSettleable) => {
        const {amountToApproveInPence, benefitSettlement} = settleable
        settleable.setErrors({
            amountToApproveInPence: !isFinite(amountToApproveInPence) ? 'required' :
                amountToApproveInPence > benefitSettlement.absoluteBenefitRemainingInPence ? 'exceeds benefit' :
                amountToApproveInPence > benefitSettlement.eligibleTotalInPence ? 'exceeds eligible' : undefined
        })
    }

    createEffect(() => {
        toSettle.forEach(it => validate(it))
        const errors = toSettle.find(it => it.hasErrors()) ? ['please fix approval errors'] : []
        props.onChange({
            planYearBenefitApprovalsInPence: planYearBenefitApprovalsInPence(),
            planYearApplicableExcessInPence: planYearApplicableExcessInPence(),
            errors
        })
    })

    const preExcessApprovedTotalInPenceFor = (excessGroup: PlanYearSettlementExcessGroup) => {
        const benefiIdsInTheGroup = excessGroup.eligibleBenefitSettlements.map(it => it.id)
        const liveSettlementsForTheGroup = toSettle.filter(it => benefiIdsInTheGroup.includes(it.id))
        return sumBy(liveSettlementsForTheGroup, it => it.amountToApproveInPence ?? 0)
    }

    const postExcessApprovedTotalInPenceFor = (excessGroup: PlanYearSettlementExcessGroup) => {
        const groupTotalApprovedInPence = preExcessApprovedTotalInPenceFor(excessGroup)
        return groupTotalApprovedInPence - excessGroup.applicableExcessInPenceIfApprovalOf(groupTotalApprovedInPence)
    }

    const planYearBenefitApprovalsInPence = () => {
        return toSettle.map(it => new PlanYearBenefitAmount({
            planYearId: it.benefitSettlement.planYear.id,
            benefitType: it.benefitSettlement.benefit.type,
            amountInPence: it.amountToApproveInPence
        }))
    }

    const planYearApplicableExcessInPence = () => {
        return groupedSettleables.flatMap(planYearSettlement =>
            planYearSettlement.excessGroups.filter(it => it.excessApplies()).flatMap(excessGroup => {
                const groupTotalApprovedInPence = preExcessApprovedTotalInPenceFor(excessGroup)
                return new PlanYearExcessAmount({
                    planYearId: planYearSettlement.planYear.id,
                    excessId: excessGroup.excess.id,
                    amountInPence: excessGroup.applicableExcessInPenceIfApprovalOf(groupTotalApprovedInPence)
                })
            })
        )
    }

    const totalPayableInPence = () => {
        const totalApprovedInPence = sumBy(toSettle, it => it.amountToApproveInPence ?? 0)
        const totalApplicableExcessInPence = sumBy(planYearApplicableExcessInPence(), it => it.amountInPence)
        return totalApprovedInPence - totalApplicableExcessInPence
    }

    const totalPayableInPenceFor = (planYear: PlanYear) => {
        const planYearsExcessInPence = sumBy(planYearApplicableExcessInPence().filter(it => it.planYearId === planYear.id), it => it.amountInPence)
        const planYearsApprovedAmountInPence = sumBy(planYearBenefitApprovalsInPence().filter(it => it.planYearId === planYear.id), it => it.amountInPence)
        return planYearsApprovedAmountInPence - planYearsExcessInPence
    }

    return <table class={classList(s.table, s.numeric, 'settlement-approval')}>
            <For each={groupedSettleables}>{ planYearSettlement =>
                <>
                    <thead>
                        <PlanYearHeadingRow colspan={5} planYear={planYearSettlement.planYear}/>
                        <tr>
                            <td></td>
                            <td class={s.textual}>Benefit</td>
                            <td>Benefit Remaining</td>
                            <td>Eligible</td>
                            <td>Approve</td>
                        </tr>
                    </thead>
                    <tbody>
                        <For each={planYearSettlement.getExcessGroupsOrderedBy(PlanYearSettlementExcessGroup.WithExcessFirst)}>{ excessGroup =>
                            <>
                                <For each={excessGroup.eligibleBenefitSettlements}>{ benefitSettlement => {
                                    const editableSettlement = toSettleApi.get(benefitSettlement.id)
                                    return (
                                        <tr class='approval-item'>
                                            <td/>
                                            <CommonSettlementTableData benefitSettlement={benefitSettlement}/>
                                            <td class='approve'>
                                                <InputCurrency
                                                    value={penceToPounds(editableSettlement.amountToApproveInPence ?? 0 )}
                                                    onBlur={() => editableSettlement.visit('amountToApproveInPence')}
                                                    error={editableSettlement.getErrorIfVisitedFor('amountToApproveInPence')}
                                                    warning={editableSettlement.amountToApproveInPence === 0 ? 'are you sure?' : undefined}
                                                    onChange={value => toSettleApi.patchById(editableSettlement.id, {amountToApproveInPence: poundsToPence(value) ?? 0})}
                                                />
                                            </td>
                                        </tr>
                                    )
                                }}</For>
                                <Show when={planYearSettlement.hasMultipleGroups() || excessGroup.excessApplies()}>
                                    {/* excess group/non excess group total */}
                                    <SubtotalRow>
                                        <td colspan={4}>
                                            <SubtotalHead>Total</SubtotalHead>
                                            <Show when={excessGroup.excessApplies()}>
                                                <SubtotalHead>Excess</SubtotalHead>
                                                <SubtotalHead>Total after excess</SubtotalHead>
                                            </Show>
                                        </td>
                                        <td>
                                            <div class="pre-excess-total">{currencyFromPence(preExcessApprovedTotalInPenceFor(excessGroup))}</div>
                                            <Show when={excessGroup.excessApplies()}>
                                                <div class="excess">{currencyFromPence(-excessGroup.applicableExcessInPenceIfApprovalOf(preExcessApprovedTotalInPenceFor(excessGroup)))}</div>
                                                <div class="post-excess-total">{currencyFromPence(postExcessApprovedTotalInPenceFor(excessGroup))}</div>
                                            </Show>
                                        </td>
                                    </SubtotalRow>
                                </Show>
                            </>
                        }</For>
                        {/* plan year total */}
                        <SubtotalRow>
                            <td colspan={4}>
                                <SubtotalHead>{readablePlanYearShort(planYearSettlement.planYear)} Total</SubtotalHead>
                            </td>
                            <td class="plan-year-total">
                                <strong>{currencyFromPence(totalPayableInPenceFor(planYearSettlement.planYear))}</strong>
                            </td>
                        </SubtotalRow>
                        <tr><td>&nbsp;</td></tr>
                    </tbody>
                </>
            }</For>
            <tbody>
                {/* overall payable total */}
                <PayableTotalRow>
                    <td colspan={4}><SubtotalHead>Payable</SubtotalHead></td>
                    <td class="payable-total">{currencyFromPence(totalPayableInPence())}</td>
                </PayableTotalRow>
            </tbody>
        </table>
}

type CommonSettlementTableDataProps = {
    benefitSettlement: PlanYearBenefitSettlement
    hideBenefitRemaining?: boolean
    hideEligible?: boolean
}
function CommonSettlementTableData({benefitSettlement, ...props}: CommonSettlementTableDataProps) {
    const benefitLimitStyle = () => ({color: benefitSettlement.eligibleTotalExceedsBenefitRemaining ? 'var(--warning)' : 'var(--text-color)'})
    return <>
        <td class={classList(s.textual, 'benefit')}>
            {prettyPrintBenefit(benefitSettlement.benefit)}&nbsp;
            <Show when={benefitSettlement.benefit.hasBeenCancelled} fallback={<span>[Active]</span>}>
                <span style={{color: 'var(--warning)'}}>[Cancelled {ukStyleDate(benefitSettlement.benefit.endDate)}]</span>
            </Show>
        </td>
        <Show when={!props.hideBenefitRemaining}>
            <td class='benefit-remaining' style={benefitLimitStyle()}>{currencyFromPence(benefitSettlement.absoluteBenefitRemainingInPence)}</td>
        </Show>
        <Show when={!props.hideEligible}>
            <td class='eligible' style={benefitLimitStyle()}>{currencyFromPence(benefitSettlement.eligibleTotalInPence)}</td>
        </Show>
    </>
}

function PlanYearHeadingRow(props: ParentProps & {planYear: PlanYear, colspan: number}) {
    return <tr class={s.textual} style={{'background-color': 'var(--peachy-green-light)', 'border-top': '2px solid black'}}>
        <th colspan={props.colspan} class={`plan-year-${props.planYear.id}`} style={{'font-size': 'initial'}}>{readablePlanYearShort(props.planYear)}</th>
    </tr>
}
function SubtotalRow(props: ParentProps) {
    return <tr style={{'background-color': 'aliceblue'}}>{props.children}</tr>
}
function PayableTotalRow(props: ParentProps) {
    return <tr style={{'background-color': 'var(--peachy-purple-light)', 'border-top': '2px solid black', 'font-weight': 'bold'}}>{props.children}</tr>
}
function SubtotalHead(props: ParentProps) {
    return <div class={s.textual} style={{'font-size': '0.75rem', 'line-height': '1rem', 'font-weight': 700, 'text-transform': 'uppercase'}}>
        <strong>{props.children}</strong>
    </div>
}

function SettlementSummary() {

    const api = useClaimProcessingApi()

    const liveMode = () => !api.readOnly || api.forceEditMode

    const settledMode = () => api.claimIsApproved() && !liveMode()

    const settlements = () => api.planYearExcessBenefitSettlements()

    const payableInPence = () => sumBy(settlements(), it => it.postExcessTotalInPence())

    return (
        <table class={classList(s.table, s.numeric, 'settlement-summary')}>
            <For each={settlements()}>{ planYearSettlement =>
                <>
                    <thead>
                        <PlanYearHeadingRow colspan={liveMode() ? 4 : 3} planYear={planYearSettlement.planYear}/>
                        <tr>
                            <td></td>
                            <th class={s.textual}>Benefit</th>
                            <Show when={liveMode()}>
                                <td>Benefit Remaining</td>
                                <td>Eligible</td>
                            </Show>
                            <Show when={settledMode()}>
                                <td>Approved</td>
                            </Show>
                        </tr>
                    </thead>
                    <tbody>
                        <For each={planYearSettlement.getExcessGroupsOrderedBy(PlanYearSettlementExcessGroup.WithExcessFirst)}>{ excessGroup =>
                            <>
                                <For each={excessGroup.eligibleBenefitSettlements}>{ benefitSettlement =>
                                    <tr class="summary-item">
                                        <td/>
                                        <CommonSettlementTableData
                                            benefitSettlement={benefitSettlement}
                                            hideBenefitRemaining={settledMode()}
                                            hideEligible={settledMode()}/>
                                        <Show when={settledMode()}>
                                            <td class='approve'>{currencyFromPence(benefitSettlement.approvedAmountInPence)}</td>
                                        </Show>
                                    </tr>
                                }</For>
                                <Show when={settledMode() && (planYearSettlement.hasMultipleGroups() || excessGroup.excessApplies())}>
                                    {/* excess group/non excess group total */}
                                    <SubtotalRow>
                                        <td colspan={2}>
                                            <SubtotalHead>Total</SubtotalHead>
                                            <Show when={excessGroup.excessApplies()}>
                                                <SubtotalHead>Excess</SubtotalHead>
                                                <SubtotalHead>Total after excess</SubtotalHead>
                                            </Show>
                                        </td>
                                        <td>
                                            <div class="pre-excess-total">{currencyFromPence(excessGroup.preExcessSettlementTotalInPence())}</div>
                                            <Show when={excessGroup.excessApplies()}>
                                                <div class="excess">{currencyFromPence(-(excessGroup.excessUsageInPence ?? 0))}</div>
                                                <div class="post-excess-total">{currencyFromPence(excessGroup.postExcessSettlementTotalInPence())}</div>
                                            </Show>
                                        </td>
                                    </SubtotalRow>
                                </Show>
                            </>
                        }</For>
                        <Show when={settledMode()}>
                            {/* plan year total */}
                            <SubtotalRow>
                                <td colspan={2}>
                                    <SubtotalHead>{readablePlanYearShort(planYearSettlement.planYear)} Total</SubtotalHead>
                                </td>
                                <td class="plan-year-total">
                                    <strong>{currencyFromPence(planYearSettlement.postExcessTotalInPence())}</strong>
                                </td>
                            </SubtotalRow>
                            <tr><td>&nbsp;</td></tr>
                        </Show>
                    </tbody>
                </>
            }</For>
            <Show when={settledMode()}>
                <tbody>
                    {/* overall payable total */}
                    <PayableTotalRow>
                        <td colspan={2}><SubtotalHead>Payable</SubtotalHead></td>
                        <td class="payable-total">{currencyFromPence(payableInPence())}</td>
                    </PayableTotalRow>
                </tbody>
            </Show>
        </table>
    )
}

export function HospitalAdmissionsCapture() {

    const api = useClaimProcessingApi()
    const [items, itemsApi] = [api.hospitalAdmissions, api.hospitalAdmissionsApi]

    const ch = createColumnHelper<ValidatableHospitalAdmission>()

    const columns: ColumnDef<ValidatableHospitalAdmission>[] = [
        ch.accessor(item => item.admissionDate, {
            header: 'Admission Date',
            cell: props => <Html5DatePicker
                    value={props.getValue()}
                    onBlur={admissionDate => {
                        item(props).visit('admissionDate')
                        itemsApi.patchById(itemId(props), {admissionDate})
                    }}
                    error={item(props).getErrorIfVisitedFor('admissionDate')}
                    disabled={api.readOnly}
                    />
        }),
        ch.accessor(item => item.dischargeDate, {
            header: 'Discharge Date',
            cell: props => <Html5DatePicker
                    value={props.getValue()}
                    onBlur={dischargeDate => {
                        item(props).visit('dischargeDate')
                        itemsApi.patchById(itemId(props), {dischargeDate})
                    }}
                    error={item(props).getErrorIfVisitedFor('dischargeDate')}
                    disabled={api.readOnly}
                    />
        }),
        ch.display({
            header: ' ',
            cell: props => <BinIcon hidden={api.readOnly} onClick={() => itemsApi.removeById(itemId(props))}/>
        }),
    ]

    const table = createSolidTable({
        get data() {
            return items.map(it => ({...it}))
        },
        columns,
        getCoreRowModel: getCoreRowModel()
    })

    return <Table table={table} rowClass={item => api.isHighlighted(item) ? s.newRowHighlight : undefined}/>
}


export function ClaimActivityReasonCapture() {
    const api = useClaimProcessingApi()
    const [_, itemsApi] = [api.reasonsForClaimActivity, api.reasonsForClaimActivityApi]

    const ch = createColumnHelper<ValidatableClaimActivitySubmissionReason>()

    const columns: ColumnDef<ValidatableClaimActivitySubmissionReason>[] = [
        ch.accessor(item => item.symptoms, {
            header: 'Symptoms',
            cell: props => <AutocompleteMultiple <TerminologyItem>
                onChange={(_, symptoms) => itemsApi.patchById(itemId(props), {symptoms})}
                selected={props.getValue()}
                onBlur={() => item(props).visit('symptoms')}
                error={item(props).getErrorIfVisitedFor('symptoms')}
                disabled={api.readOnly || item(props).readOnly}
                optionTextFn={it => it?.display ?? ''}
                optionComparisonValueFn={it => it?.code}
                lookupClient={{
                    lookup: async searchTerm => ({results: await api.searchSymptomsTerminology(searchTerm), searchTerm})
                }}/>
        }),
        ch.accessor(item => item.disorder?.display, {
            header: 'Disorder',
            cell: props => <Autocomplete <TerminologyItem>
                onChange={disorder => itemsApi.patchById(itemId(props), {disorder})}
                initialSearchTerm={props.getValue()}
                onBlur={() => item(props).visit('disorder')}
                error={item(props).getErrorIfVisitedFor('disorder')}
                resetStateOnClearInput
                disabled={api.readOnly || item(props).readOnly}
                optionTextFn={it => it?.display ?? ''}
                optionComparisonValueFn={it => it?.code}
                lookupClient={{
                    lookup: async searchTerm => ({results: await api.searchConditionsTerminology(searchTerm), searchTerm})
                }}/>
        }),
        ch.accessor(item => item.onsetDate, {
            id: 'Onset Date',
            header: props => <>
                                <div>{props.column.id}</div>
                                <Show when={api.customerDeclaredSymptomsOnsetDate}>
                                    <span style={THIN}>{ukStyleDate(api.customerDeclaredSymptomsOnsetDate)} declared</span>
                                </Show>
                            </>,
            cell: props =>  <Html5DatePicker
                            value={props.getValue()}
                            onBlur={onsetDate => {
                                item(props).visit('onsetDate')
                                itemsApi.patchById(itemId(props), {onsetDate})
                            }}
                            error={item(props).getErrorIfVisitedFor('onsetDate')}
                            disabled={api.readOnly || item(props).readOnly}
                            />
        }),
        ch.display({
            header: ' ',
            cell: props => <BinIcon hidden={api.readOnly || api.allPossibleReasonsForSubmission().length === 1 || item(props).readOnly} onClick={() => itemsApi.removeById(itemId(props))}/>
        }),
    ]

    const table = createSolidTable({
        get data() {
            // this nonsense forces the data to refresh on change when coming from a store.  There's gotta be a better way?!
            return api.allPossibleReasonsForSubmission().map(it => ({...it}))
        },
        columns,
        getCoreRowModel: getCoreRowModel()
    })

    return <Table table={table} rowClass={item => {
        return api.isHighlighted(item) ? s.newRowHighlight :
        item.readOnly ? s.readOnlyRow : undefined
    }}/>
}

export function RequestedTreatmentCapture() {

    const api = useClaimProcessingApi()
    const [items, itemsApi] = [api.requestedTreatments, api.requestedTreatmentsApi]

    const ch = createColumnHelper<ValidatableCoverCheckRequestedTreatment>()

    const columns: ColumnDef<ValidatableCoverCheckRequestedTreatment>[] = [

        ch.accessor(item => item.procedure?.display, {
            id: 'Description',
            header: props => <>
                                <div>{props.column.id}</div>
                                <Show when={api.customerDeclaredTreatment}>
                                    <ThinTextSpan>{api.customerDeclaredTreatment} declared</ThinTextSpan>
                                </Show>
                            </>,
            cell: props => <Autocomplete <TerminologyItem>
                onChange={procedure => itemsApi.patchById(itemId(props), {procedure})}
                initialSearchTerm={props.getValue()}
                onBlur={() => item(props).visit('procedure')}
                error={item(props).getErrorIfVisitedFor('procedure')}
                disabled={api.readOnly}
                optionTextFn={it => it?.display ?? ''}
                optionComparisonValueFn={it => it?.code}
                lookupClient={{
                    lookup: async searchTerm => ({results: await api.searchProceduresTerminology(searchTerm), searchTerm})
                }}/>
        }),
        ch.accessor(item => item.reasonId, {
            header: 'Treating Reason',
            cell: props => <Flex row alignItemsCenter>
                            <span style={{'margin-right': '1ch'}}>
                                <Select
                                    pleaseChoose="Please choose"
                                    options={api.allPossibleReasonsForSubmission().filter(it => it.isValid())}
                                    onBlur={() => item(props).visit('reasonId')}
                                    error={item(props).getErrorIfVisitedFor('reasonId')}
                                    warning={item(props).getWarningFor('reasonId')}
                                    selected={props.getValue()}
                                    optionValueFn={it => it.id}
                                    optionTextFn={prettyPrintReason}
                                    onChange={reason => itemsApi.patchById(itemId(props), {reasonId: reason?.id})}
                                    disabled={api.readOnly}/>
                                </span>
                                <AddButton onClick={() => {
                                        const addedReason = api.addDraftReasonForClaimActivity({highlight: true})
                                        itemsApi.patchById(item(props).id, {reasonId: addedReason.id})
                                    }} disabled={api.readOnly}/>
                            </Flex>
        }),
        ch.accessor(item => item.benefitType, {
            id: 'Benefit',
            header: props => <>
                                <div>{props.column.id}</div>
                                <Show when={api.customerDeclaredBenefitText}>
                                    <ThinTextSpan>{api.customerDeclaredBenefitText} declared</ThinTextSpan>
                                </Show>
                            </>,
            cell: props => <Select
                            pleaseChoose={!props.getValue() ? 'Please choose' : undefined}
                            options={api.getAllBenefitTypesThatCanEverBeCoverChecked()}
                            onBlur={() => item(props).visit('benefitType')}
                            error={item(props).getErrorIfVisitedFor('benefitType')}
                            warning={item(props).getWarningFor('benefitType')}
                            selected={props.getValue()}
                            optionTextFn={it => prettyPrintBenefit(it)}
                            onChange={benefitType => itemsApi.patchById(itemId(props), {benefitType})}
                            disabled={api.readOnly}
                        />
        }),
        ch.accessor(item => item.approved, {
            header: 'Approved',
            cell: props => <Toggle
                            isOn={item(props).approved}
                            onToggle={approved => itemsApi.patchById(itemId(props), {approved})}
                            disabled={api.readOnly} />
        }),


        ch.display({
            header: ' ',
            cell: props => <BinIcon hidden={api.readOnly || itemsApi.hasExactlyOneItem()} onClick={() => itemsApi.removeById(itemId(props))}/>
        }),
    ]



    const table = createSolidTable({
        get data() {
            return items.map(it => ({...it}))
        },
        columns,
        getCoreRowModel: getCoreRowModel()
    })

    return <div>
            <Show when={api.customerDeclaredCostInPence}>
                <p style={{...THIN, color: 'var(--warning)'}}>
                    Customer expects cost to be: {currencyFromPence(api.customerDeclaredCostInPence)}
                </p>
            </Show>
            <Table table={table} rowClass={item => api.isHighlighted(item) ? s.newRowHighlight : undefined}/>
        </div>
}

function LinkCoverChecks() {
    const api = useClaimProcessingApi()


    return <SelectMultiple
        options={api.coverChecks()}
        selected={api.linkedCoverChecks().map(it => it.id)}
        optionValueFn={it => it.id}
        optionTextFn={coverCheckSummary}
        onChange={coverChecks => api.setLinkedCoverChecks(coverChecks)}
        disabled={api.readOnly}
        />
}

function BinIcon(props: {onClick: () => void, hidden?: boolean}) {
    const onClick = () => {
        !props.hidden && props.onClick()
    }
    const classes = classList('fa-solid', 'fa-trash', !props.hidden ? s.clickable : '')

    return <Show when={!props.hidden}>
                <i onClick={onClick} class={classes}/>
           </Show>
}



function coverCheckSummary(coverCheck: ClaimActivity) {
    const benefits = coverCheck.assessedBenefits.map(prettyPrintBenefit)
    if (isEmpty(benefits)) {
        benefits.push(prettyPrintBenefit(coverCheck.customerDeclaredBenefitType))
    }
    return `${ukStyleDate(coverCheck.dateSubmitted)} - ${coverCheck.referenceNumber} // ${benefits} // ${prettyPrintDecision(coverCheck.decision)}`
}



function itemId<T extends HasId>(cellContext: CellContext<T, any>): T['id'] {
    return item(cellContext).id
}

function item<T>(cellContext: CellContext<T, any>) {
    return cellContext.row.original
}

function prettyPrintReason(reason: ClaimActivitySubmissionReason) {
    const symptoms = reason.symptoms.map(it => it?.display)
    const disorders = reason.disorder ? [reason.disorder.display] : []
    return [...disorders, ...symptoms].join(' | ')
}
